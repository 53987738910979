// General settings
.section {

	@include font-size(22px);
	
	position: relative;

	img {

		max-width: 100%;
		display: block;
	}

	.content--wrapper {

		display: flex;
		flex-direction: column;

		@include bp($bp-nexus) {

			flex-direction: row;
		}
	}

	.image {

		width: 100%;
		background-size: cover;
		background-position: 50%;
		min-height: 450px;

		@include bp($bp-nexus) {

			width: 50%;
		}
	}

	.content {

		width: 100%;
		padding: 35px 20px 50px;
		text-align: left;
		transition: all 0.3s ease-in;

		@include bp($bp-nexus) {

			width: 50%;
			padding-top: percentage(($col_width - 60) /$max_width);
			padding-bottom: percentage(($col_width - 40) /$max_width);
			padding-left: percentage(($col_width - 80)/$max_width);
			padding-right: percentage(($col_width - 80)/$max_width);
		}

		@include bp($bp-ipad-portrait) {

			padding-left: percentage(($col_width - 60)/$max_width);
			padding-right: percentage(($col_width - 60)/$max_width);
		}

		@include bp($bp-laptop) {

			padding-left: percentage(($col_width + 20)/$max_width);
			padding-right: percentage(($col_width - 40)/$max_width);
		}

		h2 {

			@include font-size(35px);

			line-height: calculateRem(55px);
			color: $blue-green;
			font-weight: 400;
			margin: 0 0 calculateRem(60px);
			padding: 0;
		}

		ul {

			list-style: none;
			margin: 0;
			padding: 0;
			margin-bottom: calculateRem(20px);

			@include bp($bp-nexus) {
				
				margin-bottom: calculateRem(40px);
			}
		}

		li {

			position: relative;
			padding-left: calculateRem(40px);
			margin-bottom: 0;
		}

		p {

			margin: 0;
		}

		.icon {

			display: block;
			width: calculateRem(22px);
			height: calculateRem(22px);
			position: absolute;
			left: 0;
			top: 50%;
			color: $black;
			transform: translateY(-50%);
		}
	}

	.pipe-image {

		position: absolute;
		z-index: 10000;
	}
}



// Homepage Feature
.section--feature {

	min-height: 300px;
	transition: all 0.3s ease-in;
	background-size: cover;
	background-position: 50%;
	height: 15px;
	padding-top: percentage(750 / $max_width);
}



// Homepage Intro
.section--intro {

	@include font-size(32px);

	background-color: $blue-green;
	color: $white;

	.content {

		margin-left: auto;
		width: 100%;
		padding: 80px 40px 50px;

		@include bp($bp-nexus) {

			padding-left: percentage((($col_width * 4) - 30)/$max_width);
			padding-right: percentage(($col_width + $offset)/$max_width);
			padding-bottom: percentage(($offset + 20)/$max_width);
		}
	}

	.more {

		@include font-size(24px);

		transition: all 0.3 ease-in-out;
		color: $white;
		text-decoration: underline;
		margin-top: 40px;
		display: inline-block;

		@include bp($bp-nexus) {

			margin-top: calculateRem(100px);	
		}

		&:hover {

			text-decoration: none;
		}
	}

	.pipe-image {

		transform: translateX(-65%) translateY(-50%);
		width: percentage( 242 / $max_width );
		min-width: 100px;
		left: 50%;
		transition: all 0.3s ease-in;

		@include bp($bp-nexus) {

			left: percentage( ( $offset - 15 ) / $max_width );
			transform: translateX(0) translateY(-12%);
		}
	}
}



.section--services-1 {

	.content--wrapper {

		// flex-direction: row-reverse;

		@include bp($bp-nexus) {

			flex-direction: row-reverse;
		}
	}
}

.section--services-2 {

	background-color: $black;
	color: $white;

	.content {
		
		width: 100%;
		padding-top: calculateRem(100px);

		@include bp($bp-nexus) {

			width: percentage((($col_width * 6) - 50)/ $max_width);
			padding-top: percentage(($col_width - 60)/$max_width);
		}

		h2 {

			color: $white;	
		}
		
		.icon {

			color: $white;
		}
	}

	.image {

		width: 100%;
		
		@include bp($bp-nexus) {

			width: percentage((($col_width * 6) + 50)/ $max_width);
		}
	}

	.pipe-image {

		width: percentage( 140 / $max_width );
		min-width: 100px;
		position: absolute;
		left: 20%;
		transform: translateX(-40%) translateY(0);
		top: 425px;
		transition: all 0.3s ease-in;

		@include bp($bp-nexus) {

			left: 50%;
			top: calculateRem(50px);
			width: percentage( 200 / $max_width );
		}

		@include bp($bp-laptop) {

			transform: translateX(-45%) translateY(0);
			top: calculateRem(100px);
			width: percentage( 302 / $max_width );
		}
	}
}



.section--clients {

	.content--wrapper {

		@include bp($bp-nexus) {

			flex-direction: row-reverse;
		}
	}

	.content {

		ul {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;

			@include bp($bp-nexus) {

				justify-content: space-between;
			}
		}

		li {

			width: 50%;
			margin: calculateRem(40px) 0 calculateRem(50px);
			text-align: center;
			padding: 0 calculateRem(25px);
			// max-height: calculateRem(58px);

			@include bp($bp-nexus) {

				width: 33.33%;
				padding: 0 calculateRem(10px);
			}

			img {

				display: inline-block;
				vertical-align: bottom;
				width: auto;
			}
		}
	}
}



.section--quote {

	background-color: $blue-green;

	.pipe-image {

		width: percentage( 187 / $max_width );
		transform: translateY(20%);
		position: absolute;
		right: percentage( 122 / $max_width );
		transition: all 0.3s ease-in;

		@include bp($bp-nexus) {

			transform: translateY(-43%);
		}
	}

	.content {

		width: 100%;
		padding: 50px 40px 50px;

		@include bp($bp-nexus) {

			padding-left: percentage(($col_width + $offset)/$max_width );
			padding-right: percentage(($col_width + $offset)/$max_width );
			padding-top: percentage(($offset * 2)/$max_width );
			padding-bottom: percentage((($offset * 2) + 20)/$max_width );			
		}



		h2 {

			@include font-size(50px);
			@include line-height(1.3);

			color: $white;
			margin-bottom: calculateRem(60px);

			@include bp($bp-nexus) {

				@include font-size(78px);

				margin-bottom: calculateRem(100px);
			}
		}
	}
}





