/*---------------------------------------------*\
  #Fonts size mixing
\*---------------------------------------------*/

// px to rem
@function calculateRem($size) {
  $remSize: $size / $base_font_size;
  @return $remSize * 1rem;
}

// Font size
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}



/*---------------------------------------------*\
	#Line height
\*---------------------------------------------*/

@mixin line-height($val: 1) {
  
  @if unit($val) == "" {
    line-height: $val;    
  }
  
  @if unit($val) == "px" {
    line-height: $val;
    line-height: calculateRem($val);
  }
}



/*---------------------------------------------*\
	#Media Query (Mobile First)
\*---------------------------------------------*/

@mixin bp($px) {
    @media only screen and (min-width: $px) {
      @content;
    }
}

@mixin bp-max ($px) {

    @media screen and (max-width: $px - 1px) {
      @content;
    }
}



/*---------------------------------------------*\
  #Clear-fix
\*---------------------------------------------*/

@mixin clear-fix ($type : fix) {

    @if $type == left {

        clear: left;
    } @else if $type == right {

        clear: right;
    } @else if $type == both {

        clear: both;
    } @else {

        *zoom: 1;

        &:before, &:after {

            content: '';
            display: table;
        }

        &:after {

            clear: both;
        }
    }
}

.clear-fix {

    @include clear-fix();
}





