.icon {
	content: '';
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    position: relative;

    svg {

    	width: 100%;
    	height: 100%;
    }
}