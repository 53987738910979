/*---------------------------------------------*\
	# Global Settings/Overrides
\*---------------------------------------------*/

* {

	box-sizing: border-box;
	// outline: 1px solid red;
}

a, a:hover, button, input, textarea, input:focus textarea:focus {

	outline: none !important;
}

a:hover {

	text-decoration: none;
}

body, html{

	@extend .html;

	background-color: $grey;
	background-position: 50%;
	background-size: cover;
	border: 0;
	color: $text_color;
	font-family: $font_primary;
	height: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	text-align: center;
	width: 100%;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.wrapper {

	width: 100%;
	position: relative;
	padding: 0;
	margin: 0;
	background-color: $grey;
}

article {

	max-width: #{$max_width}px;
	margin: 0 auto;
	padding: 0;
	background-color: $white;
}

.content--wrapper {

	width: 100%;
	padding: 0;
	position: relative;
}

input, textarea, input:focus, textarea:focus {

	border: 1px solid $white;
	background-color: transparent;
	padding: calculateRem(20px);
	appearance: none;
    box-shadow: none;
	transition: all 0.3s ease-in-out;

	&::-webkit-input-placeholder { 
		color: $black;
	}
	
	&::-moz-placeholder { 
		color: $black;
	}
	
	&:-ms-input-placeholder {
		color: $black;
	}

	&:-moz-placeholder { 
		color: $black;
	}
}

input:focus, textarea:focus {

	border: 1px solid rgba($white, 0.4);

	&::-webkit-input-placeholder { 
		color: rgba($black,0.4);
	}
	
	&::-moz-placeholder { 
		color: rgba($black,0.4);
	}
	
	&:-ms-input-placeholder {
		color: rgba($black,0.4);
	}

	&:-moz-placeholder { 
		color: rgba($black,0.4);
	}
}