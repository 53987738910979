// /*---------------------------------------------*\
// 	# Header
// \*---------------------------------------------*/
header {

	max-width: #{$max_width}px;
	margin: 0 auto;
	background-color: $white;

	.content__wrapper {

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px 30px 20px 15px;
		transition: all 0.3s ease-in-out;

		@include bp($bp-nexus) {

			padding: percentage($offset/$max_width) percentage(($col_width + $offset)/$max_width) percentage(($offset + 20)/$max_width);
		}
	}

	.phone {

		@include font-size(40px);

		color: $blue-green;
		text-decoration: none;

		img {

			margin-right: 0;
			width: calculateRem(45px);
			transition: all 0.3s ease-in-out;

			@include bp($bp-nexus) {

				width: calculateRem(25px);
				margin-right: calculateRem(25px);
			}
		}

		span {

			display: none;

			@include bp($bp-nexus) {

				display: inline;
			}
		}
	}

	.tagline {

		@include font-size(40px);
		
		line-height: calculateRem(44px);
		width: 100%;
		text-align: left;
		padding-top: percentage(($col_width - 30)/$max_container_width);
		color: $blue-green;

		@include bp($bp-nexus) {

			line-height: calculateRem(52px);
		}
	}
}





