.html {

	@include line-height(1.6);

	font-size: $base_font_size - 6; // 18px
	font-weight: 400;

	@include bp($bp-iphone-portrait) {

		font-size: $base_font_size - 5; // 17px
	}

	@include bp($bp-nexus) {
		
		font-size: $base_font_size - 4; // 18px
	}

	@include bp($bp-ipad-landscape) {

		font-size: $base_font_size - 2; // 20px
	}

	@include bp($bp-laptop) {

		font-size: $base_font_size; // 22px
	}
}

 



