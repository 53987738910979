// /*---------------------------------------------*\
// 	# Logo
// \*---------------------------------------------*/
.logo {

	display: inline-block;

	img {

 		width: 100%;
	}
}

.logo--header {

	min-width: 132px;
	width: percentage( 241 / $max_container_width);
	margin: 0;
}

.logo--footer {

	width: 132px;
	margin: 0 auto;

	@include bp($bp-nexus) {

		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		bottom: 0;
	}
}





