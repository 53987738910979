form {

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;

	.item {

		
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom:0;

		@include bp($bp-nexus) {

			width: percentage( (($col_width * 5) + 30) / $max_container_width);
			margin-bottom: calculateRem(35px);
		}
	}

	.item--textarea {

		width: 100%;

		@include bp($bp-nexus) {

			width: percentage( ($col_width * 4) / $max_container_width);
		}
	}

	input[type="text"], input[type="email"] {

		width: 100%;
		height: calculateRem(80px);
    	margin-bottom: calculateRem(35px);

		@include bp($bp-nexus) {

			width: percentage( 400/ (($col_width * 5) + 30));
			margin-bottom: 0;
		}
	}

	input[type="text"]:first-of-type {
		
		margin-bottom: calculateRem(35px);
	}

	textarea {

		width: 100%;
    	margin-bottom: calculateRem(35px);

		@include bp($bp-nexus) {

			margin-bottom: 0;
		}
	}

	button[type="submit"] {

		height: calculateRem(80px);
		padding: calculateRem(20px) calculateRem(100px);
		border: 0;
		background-color: $white;
		color: $black;
		margin-left: auto;
		width: 100%;

		@include bp($bp-iphone-landscape) {

			width: auto;
		}
	}
}