
/*---------------------------------------------*\
    # Slick dots
\*---------------------------------------------*/
.slick-dots {

    @include clear-fix();

    position: absolute;
    bottom: calculateRem(435px);
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    right: 0;
    display: none !important;

    @include bp($bp-ipad-portrait) {
        
        display: block !important;
    }

    li {

        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        display: block;
        // background-color: $trans;
        float: left;
        clear: both;

        button {

            border-radius: 0;
            border: 0;
            border: 0;
            cursor: pointer;
            display: block;
            font-size: 0;
            height: 2px;
            line-height: 0;
            outline: none;
            padding: 7px 0;
            position: relative;
            text-indent: -9999px;
            width: 50px;
            background-color: $trans;

            &:before {

                content: '';
                display: block;
                position: absolute;
                height: 2px;
                background-color: $white;
                width: 50%;
                right: 0;
            }
        }
    }

    li.slick-active {
        
        button {

            &:before {
                
                width: 100%;
                background-color: $white;
            }
        }
    }
}

